import axios from 'axios';
import type {Ref} from 'vue';
import {ref} from 'vue';
import Signer2 from '@/lib/Signer2';
import EngineApi from '@/lib/EngineApi';
import {Profile} from '@/lib/ApiResources';
import App from '@/components/App.vue';
import Testing from "@/lib/Testing";

export default new class AuthManager {

	private signInPromise?: Promise<void>;

	public reactiveProfile: Ref<null | Profile> = ref(null);

	// TODO Medium: Refactor below to above reactiveProfile prop?
	private walletAddress?: String;
	private apiToken?: String;

	public reactiveIsLoggedIn: Ref<boolean> = ref(false);

	private isInEnsureCorrectWallet: Boolean = false;

	public initPromise: Promise<void>;
	public initPromiseResolve!: (arg: unknown) => void;

	constructor()
	{
		this.initPromise = new Promise(resolve => this.initPromiseResolve = resolve as any);
	}

	public async init(): Promise<void> {

		// Setup Axios' bearer token.
		this.setupAxiosWithApiToken();

		// Init access token.
		await this.testSetAccessToken() || await this.restoreLogin();

		this.initPromiseResolve(null);
	}

	private async testSetAccessToken(): Promise<boolean> {

		// Abort due to no access token present?
		let requestedApiToken = await (window as any).libAuthManager_apiToken?.();

		if (!requestedApiToken)
			return false;

		// Sign in using requested access token.
		await this.handleSignedIn(requestedApiToken, undefined);

		//
		return true;
	}

	public async restoreLogin(): Promise<void> {

		// Abort due to no data stored?
		let apiToken: string | null = window.localStorage.getItem('auth.apiToken');

		if (!apiToken)
			return;

		// Handle signed in.
		let walletAddress: string | undefined = window.localStorage.getItem('auth.walletAddress') || undefined;

		await this.handleSignedIn(apiToken, walletAddress);
	}

	public get testSeemToBeRemembered(): boolean {
		return !!window.localStorage.getItem('auth.walletAddress') && !!window.localStorage.getItem('auth.apiToken');
	}

	protected ensureCorrectWallet(): void {

		// Abort due to no wallet present?
		if (!Signer2.reactiveAddress.value)
			return;

		// Abort due to already in call stack?
		if (this.isInEnsureCorrectWallet)
			return;

		this.isInEnsureCorrectWallet = true;

		// Abort due to not signed in?
		if (!this.isSignedIn()) {
			this.isInEnsureCorrectWallet = false;

			return;
		}

		// Abort due to signer and auth has same address?
		if (Signer2.reactiveAddress.value == this.walletAddress) {
			this.isInEnsureCorrectWallet = false;

			return;
		}

		// Log out since we are not in sync with wallet.
		this.logout();

		//
		this.isInEnsureCorrectWallet = false;
	}

	public async ensureSignedIn(): Promise<boolean> {

		// Ensure correct wallet.
		this.ensureCorrectWallet();

		// Abort due to already signed in?
		if (this.apiToken) {
			return true;
		}

		// Redirect to existing promise?
		if (this.signInPromise)
			await this.signInPromise;

		// Sign in using new promise.
		else
			await (this.signInPromise = this.signInViaWallet());

		//
		return this.isSignedIn();
	}

	public async getApiToken() {

		// Make sture we are signed in.
		await this.ensureSignedIn();

		// Return API token.
		return this.apiToken;
	}

	protected async signInViaWallet() {

		// Abort due to no wallet present?
		const walletAddress: string = Signer2.reactiveAddress.value!;

		if (!walletAddress)
			return;

		// Get nonce.
		const authData = (await axios.post(EngineApi.buildUrl('/profile/auth/web3/challenge'), {
			walletAddress,
		})).data;

		// Sign none.
		const signature = await Signer2.reactiveSigner.value!.signMessage(authData.nonce.toString());

		// Pass back nonce.
		try {
			const authResult = (await axios.post(EngineApi.buildUrl('/profile/auth/web3/verify'), {
				walletAddress,
				nonce: authData.nonce.toString(),
				signature,
			})).data;

			// Handle signed in.
			await this.handleSignedIn(authResult.accessToken, walletAddress);
		} catch (ex) {
			this.handleAuthFailedException(ex);
		}
	}

	private handleAuthFailedException(ex: any): void {

		// Handle prelaunch active error?
		if ((ex as any)?.response?.status === 400 && (ex as any)?.response?.data?.message === 'Wallet connect is disabled due to prelaunch!') {

			App.getInstance().$notify({
				group: 'bottomRight',
				type: 'error',
				title: "Prelaunch active!",
				text: 'The prelaunch is active and your have not pre registered. Please wait for the prelaunch to be ended.',
			});

		}
		// Rethrow?
		else
			throw ex;
	}

	public async handleSignedIn(apiToken: string, walletAddress?: string): Promise<void> {

		// Set
		this.walletAddress = walletAddress;
		this.apiToken = apiToken;

		// Store
		window.localStorage.setItem('auth.walletAddress', walletAddress || '');
		window.localStorage.setItem('auth.apiToken', apiToken);

		// Mark as logged in.
		this.reactiveIsLoggedIn.value = true;

		// Load extra profile data.
		await this.refreshData();
	}

	public async refreshData() {

		// Set requested profile data.
		this.reactiveProfile.value = (await axios.get(EngineApi.buildUrl('/profile'))).data;
	}

	protected setupAxiosWithApiToken() {

		axios.interceptors.request.use((config: any) => {
			(config as any).headers.Authorization = this.apiToken ? 'Bearer ' + this.apiToken : null;

			return config;
		});

		// Show login screen when a 401 is returned.
		axios.interceptors.response.use((response) => {
			return response;
		}, async (error) => await this.handleAxiosResponseError(error));
	}

	private async handleAxiosResponseError(error: any) {

		// @debug
		if (Testing.isTesting) {
			console.info('handleAxiosResponseError', error);

			await setTimeout(resolve => resolve, 2000);
		}

		// Throw unknown (not not-logged-in) error?
		if (error.response?.status !== 401)
			return Promise.reject(error);

		// Is terms agreement outdated?
		else if (error.response?.data?.message === "Terms agreement outdated!") {

			// Ignore due to already on correct page (prevent loop)?
			if (window.location.pathname === '/profile/auth/terms-agreement') {
				// @note Disabled to avoid Sentry Exceptions. The upcoming unresolving await will avoid further execution.
				// return Promise.reject(error);
			}
			// Redirect to agreement page?
			else
				window.location.href = '/profile/auth/terms-agreement';
		}
		// Is just not logged in?
		else {

			// Log the user out
			this.logout();

			// Redirect to auth popup
			// @todo medium: persist current page, instead of going to the frontpage
			window.location.href = '/profile/auth';
		}

		// Avoid caller to continue. Let's redirect only.
		return new Promise(resolve => {
		});
	}

	public isSignedIn(): boolean {

		// Ensure correct wallet.
		this.ensureCorrectWallet();

		// Have a API token?
		return !!this.apiToken;
	}

	public get hasWalletAddress(): boolean {
		return !!this.walletAddress;
	}

	public logout(): void {

		// Unstore data.
		window.localStorage.removeItem('auth.walletAddress');
		window.localStorage.removeItem('auth.apiToken');

		// Clear test data.
		window.localStorage.removeItem('lib.Signer2.providedTestArtifacts');

		// Clear
		this.walletAddress = undefined;
		this.apiToken = undefined;
		this.reactiveIsLoggedIn.value = false;

		// Redirect to homepage.
		window.location.href = '/';
	}

}
